import React from 'react'
import { ArrowRightIcon, InfoIcon } from '@components/icons'
import { ConnectMergeButton } from '../settings/merge/ConnectMergeButton'
import { useMergeConnectFlow } from '@/hooks/useMergeConnectFlow'

export const ConnectAtsAlert = () => {
  const {
    mergeIntegrations,
    isMergeIntegrationsLoading,
    connectAtsMutation,
    disconnectMutation,
  } = useMergeConnectFlow()

  const onSuccess = (publicToken: string) => {
    connectAtsMutation.mutate({
      publicToken,
    })
  }

  if (isMergeIntegrationsLoading) return <></>

  if (mergeIntegrations?.atsIntegration) return <></>

  return (
    <section className="bg-white md:p-6">
      <div className="mx-auto max-w-7xl 2xl:px-6">
        <div className="mx-auto flex flex-wrap items-center gap-4 border-l-2 border-blue-600 bg-blue-50 py-4 text-blue-900 md:justify-between md:rounded-r-lg md:px-7">
          <span className="flex md:items-center">
            <InfoIcon className="ml-6 flex-none md:ml-0" />
            <span className="ml-3 hidden text-sm md:block">
              You haven’t yet connected your applicant tracking system.
            </span>
          </span>

          <a className="flex items-center gap-x-2 md:ml-9" href="#">
            <ConnectMergeButton
              integration="ats"
              onSuccess={onSuccess}
              spinnerClassName="-ml-1 mr-3 h-5 w-5 animate-spin text-blue-900"
            >
              <span className="hidden text-sm font-medium md:block ">
                Connect
              </span>
              <span className="block text-sm font-medium md:hidden ">
                Connect your ATS
              </span>
            </ConnectMergeButton>
            <ArrowRightIcon className="stroke-blue-900" />
          </a>
        </div>
      </div>
    </section>
  )
}
