import { trpc } from '@/utils/trpc'

export const useMergeConnectFlow = () => {
  const utils = trpc.useContext()
  const {
    data: mergeIntegrations,
    isLoading: isMergeIntegrationsLoading,
    isError: isErrorLoadingMergeIntegrations,
  } = trpc.useQuery(['mergeIntegrations.getIntegrationInfo'], {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false,
    context: {
      skipBatch: true,
    },
  })

  const connectAtsMutation = trpc.useMutation(
    ['mergeIntegrations.connectAts'],
    {
      onSuccess: () => {
        utils.invalidateQueries(['mergeIntegrations.getIntegrationInfo'])
        utils.invalidateQueries(['product.byUID'])
      },
    },
  )
  const connectHrisMutation = trpc.useMutation(
    ['mergeIntegrations.connectHris'],
    {
      onSuccess: () => {
        utils.invalidateQueries(['mergeIntegrations.getIntegrationInfo'])
        utils.invalidateQueries(['product.byUID'])
      },
    },
  )

  const disconnectMutation = trpc.useMutation(
    ['mergeIntegrations.disconnect'],
    {
      onSuccess: () => {
        utils.invalidateQueries(['mergeIntegrations.getIntegrationInfo'])
        utils.invalidateQueries(['product.byUID'])
      },
      onError: error => {
        console.log(error)
      },
    },
  )

  return {
    mergeIntegrations,
    isMergeIntegrationsLoading,
    isErrorLoadingMergeIntegrations,
    connectAtsMutation,
    connectHrisMutation,
    disconnectMutation,
  }
}
