import { Layout } from '@components/Layout'
import { trpc } from '@/utils/trpc'
import { usePendingReviewsQuery } from '@/hooks/usePendingReviewsQuery'
import { Meta } from '@/@types/meta'
import { ConnectAtsAlert } from '@/components/ats/ConnectAtsAlert'
import { JobCard, JobCardPlaceholder } from '@/components/jobs/JobCard'

export const Jobs = () => {
  const { data, isLoading } = trpc.useQuery(['job.manyByUser'], {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    context: {
      skipBatch: true,
    },
  })
  const { stats } = usePendingReviewsQuery()

  const meta: Meta = {
    title: `Jobs`,
  }
  return (
    <Layout meta={meta}>
      <main className="overflow-auto pb-8 lg:flex-1">
        <ConnectAtsAlert />
        {isLoading && <JobCardPlaceholder />}
        {data?.companies.map(company => (
          <div className="mx-auto max-w-7xl px-6" key={company.id}>
            <section className="container mt-6">
              <h3 className="text-2xl font-medium">{company.name} Jobs</h3>
            </section>
            <div className="mt-4 grid gap-3 rounded-2xl px-0 md:grid-cols-2 md:gap-6 lg:flex lg:flex-wrap xl:max-w-none">
              {data?.jobs
                .filter(job => job.companyId === company.id)
                .map(job => (
                  <JobCard
                    pendingReviews={
                      stats?.find(s => s.jobSlug === job.slug)?.pendingNumber
                    }
                    key={job.id}
                    {...job}
                  />
                ))}
            </div>
          </div>
        ))}
      </main>
    </Layout>
  )
}

Jobs.requireAuth = true
Jobs.requireProductGuard=true

export default Jobs
