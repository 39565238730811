import { classNames } from '@/utils/classNames'
import { SupportedIntegrationsType } from '@api/_types/merge'
import { ButtonSpinner } from '@components/spinners'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { trpc } from '@utils/trpc'
import type { FC } from 'react'

interface ConnectMergeButtonProps {
  integration: SupportedIntegrationsType
  onSuccess: (publicToken: string) => void
  className?: string
  spinnerClassName?: string
}

export const ConnectMergeButton: FC<ConnectMergeButtonProps> = props => {
  const { integration, onSuccess, className, spinnerClassName, children } =
    props
  const {
    data: linkToken,
    isFetching,
    refetch,
  } = trpc.useQuery(
    [
      'mergeIntegrations.getLinkToken',
      {
        categories: [integration],
      },
    ],
    {
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      context: {
        skipBatch: true,
      },
      enabled: false,
      onSuccess: () => {
        openMergeFlow()
      },
    },
  )

  const {
    open: openMergeFlow,
    isReady,
    error,
  } = useMergeLink({
    linkToken: String(linkToken),
    onSuccess,
  })

  const handleClick = async () => {
    refetch()
  }

  return (
    <button
      className={classNames('flex', className ?? '')}
      onClick={handleClick}
    >
      {isFetching && <ButtonSpinner className={spinnerClassName} />}
      {children}
    </button>
  )
}
