import type { FC } from 'react'
import { PeopleIcon, LocationIcon, ArrowRightIcon } from '@components/icons'
import Link from 'next/link'

interface JobCardProps {
  id: number
  slug: string | null
  name: string | null
  location: string | null
  totalOutbound: number | undefined
  pendingReviews: number | undefined
}

export const JobCard: FC<JobCardProps> = props => {
  const { id, slug, name, location, totalOutbound, pendingReviews } = props
  return (
    <article className="shadow-box flex w-full flex-col justify-end space-y-4 divide-y-2 divide-slate-100  rounded-xl bg-white p-4 lg:max-w-[20rem]">
      <Link href={`/jobs/${slug}`}>
        <a>
          <h3 className="text-sm font-medium">{name}</h3>
        </a>
      </Link>
      <ul className="mb-auto space-y-4 pt-4 font-medium">
        <li className="flex items-center justify-between ">
          <span className="flex items-center gap-2 text-xs font-medium text-slate-400">
            <span className="grid h-8 w-8 place-content-center rounded-full bg-slate-50">
              <PeopleIcon className="stroke-current" />
            </span>
            Candidates
          </span>
          <span className="text-xs font-medium">{totalOutbound}</span>
        </li>
        <li className="flex items-center justify-between">
          <span className="flex items-center gap-2 text-xs font-medium text-slate-400">
            <span className="grid h-8 w-8 place-content-center rounded-full bg-slate-50">
              <LocationIcon className="stroke-current" />
            </span>
            Location
          </span>
          <span className="text-right text-xs font-medium">{location}</span>
        </li>
      </ul>
      <div className="pt-4">
        <div className="flex items-center">
          {pendingReviews && (
            <Link href={`/reviews?job=${slug}`}>
              <a>
                <span className="grid place-content-center rounded-2xl bg-teal-50 py-1.5 px-4 text-xs font-medium text-teal-600">
                  {pendingReviews} Candidates to Review
                </span>
              </a>
            </Link>
          )}
          <Link href={`/jobs/${slug}`}>
            <a className="ml-auto grid h-8 w-8 place-content-center rounded-md bg-slate-50">
              <ArrowRightIcon className="stroke-slate-800" />
            </a>
          </Link>
        </div>
      </div>
    </article>
  )
}

export const JobCardPlaceholder: FC = props => {
  return (
    <div className="mx-auto max-w-7xl px-6">
      <section className="container mt-6">
        <h3 className="text-2xl font-medium">Loading...</h3>
      </section>
      <div className="mt-4 grid gap-3 rounded-2xl px-0 md:grid-cols-2 md:gap-6 lg:flex lg:flex-wrap xl:max-w-none">
        {[1, 2, 3].map(index => (
          <article
            className="flex w-full flex-col justify-end space-y-4 divide-y-2 divide-slate-100 rounded-xl border-2 border-slate-100 bg-white p-4 lg:max-w-[20rem]"
            key={index}
          >
            <div className="animate-pulse rounded-lg bg-slate-200 py-3" />
            <ul className="mb-auto space-y-4 pt-4 font-medium">
              <li className="w-[70%] animate-pulse rounded-lg bg-slate-200 py-3" />
              <li className="w-[30%] animate-pulse rounded-lg bg-slate-200 py-3" />
            </ul>
            <div className="pt-4">
              <div className="animate-pulse rounded-lg bg-slate-200 py-3" />
            </div>
          </article>
        ))}
      </div>
    </div>
  )
}
